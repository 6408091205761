import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { setLightness } from 'polished';
import React from 'react';

import Config from '../configs/websiteConfig';
import { Translate } from '../locales/i18n';
import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';

export const Footer: React.FC = () => {
  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <section className="copyright">
          <Link to="/">{Config.title}</Link> &copy; {new Date().getFullYear()}{' '}
          {Config.footer && Config.externalUrls.webSite && (
            <a href={Config.externalUrls.webSite ?? '/'}>| {Config.footer}</a>
          )}
          {Config.footer && !Config.externalUrls.webSite && <span>| {Config.footer}</span>}
        </section>
        <SiteFooterNav>
          <Link to="/">{Translate.page.latestPosts}</Link>
          {Config.externalUrls.facebook && (
            <a href={Config.externalUrls.facebook} target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          )}
          {Config.externalUrls.twitter && (
            <a href={Config.externalUrls.twitter} target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          )}
          {Config.externalUrls.instagram && (
            <a href={Config.externalUrls.instagram} target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          )}
          {Config.externalUrls.appStore && (
            <a href={Config.externalUrls.appStore} target="_blank" rel="noopener noreferrer">
              iOS
            </a>
          )}
          {Config.externalUrls.googlePlay && (
            <a href={Config.externalUrls.googlePlay} target="_blank" rel="noopener noreferrer">
              Android
            </a>
          )}
          {Config.externalUrls.webSite && (
            <a href={Config.externalUrls.webSite} target="_blank" rel="noopener noreferrer">
              Site
            </a>
          )}

          <a href="/rss.xml">RSS</a>
        </SiteFooterNav>
      </div>
    </footer>
  );
};

const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background: ${setLightness('0.15', colors.darkgrey)};
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  a {
    color: rgba(255, 255, 255, 0.7);
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  section {
    text-align: center;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-of-type {
      margin-left: 0;
    }
  }
`;
