import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../components/Footer';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import Config from '../configs/websiteConfig';
import { PreviewBlogPost } from '../graphql/blogPostQuery';
import { ITag } from '../graphql/tagQuery';
import IndexLayout from '../layouts';
import { Translate } from '../locales/i18n';
import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteNavMain,
  SiteArchiveHeader,
  ResponsiveHeaderBackground,
  SiteHeaderBackground,
} from '../styles/shared';

interface TagTemplateProps {
  location: Location;
  pageContext: {
    tag: string;
  };
  data: {
    allTagYaml: {
      edges: Array<{
        node: ITag;
      }>;
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: PreviewBlogPost;
      }>;
    };
  };
}

const Tags = ({ pageContext, data, location }: TagTemplateProps): JSX.Element => {
  const tag = pageContext.tag ? pageContext.tag : '';
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagData = data.allTagYaml.edges.find(n => n.node.id.toLowerCase() === tag.toLowerCase());

  return (
    <IndexLayout>
      <Helmet>
        <html lang={Config.lang} />
        <title>
          {tag} - {Config.title}
        </title>
        <meta name="description" content={tagData?.node ? tagData.node.description : ''} />
        <meta property="og:site_name" content={Config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${tag} - ${Config.title}`} />
        <meta property="og:url" content={Config.siteUrl + location.pathname} />
        {Config.externalUrls.facebook && (
          <meta property="article:publisher" content={Config.externalUrls.facebook} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${tag} - ${Config.title}`} />
        <meta name="twitter:url" content={Config.siteUrl + location.pathname} />
        {Config.externalUrls.twitter && (
          <meta
            name="twitter:site"
            content={`@${Config.externalUrls.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
      </Helmet>
      <Wrapper>
        <header className="site-archive-header" css={[SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
          <ResponsiveHeaderBackground
            css={[outer, SiteHeaderBackground]}
            backgroundImage={tagData?.node?.image?.childImageSharp?.fluid?.src}
            className="site-header-background"
          >
            <SiteHeaderContent css={inner} className="site-header-content">
              <SiteTitle className="site-title">{tag}</SiteTitle>
              <SiteDescription className="site-description">
                {tagData?.node.description ? (
                  tagData.node.description
                ) : (
                  <>
                    {Translate.posts.collection}{' '}
                    {totalCount > 1 && `${totalCount} ${Translate.posts.posts}`}
                    {totalCount === 1 && `1 ${Translate.posts.post}`}
                    {totalCount === 0 && `${Translate.posts.noPost}`}
                  </>
                )}
              </SiteDescription>
            </SiteHeaderContent>
          </ResponsiveHeaderBackground>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed]}>
              {edges.map(({ node }) => (
                <PostCard key={node.fields.slug} post={node} />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allTagYaml {
      edges {
        node {
          ...Tag
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          ...PreviewBlogPost
        }
      }
    }
  }
`;
